<template>
  <section class="editorial">
    <!-- <Loader :loading="loading" /> -->
    <template v-if="!loading">
      <router-link class="editorial__tag text-base" to="/library/publications">
        Publications
      </router-link>
      <h1 class="editorial__title text-title">{{ editorial.title }}</h1>
      <div class="editorial__info">
        <p class="editorial__info-author text-description">by {{ editorial.author }}</p>
        <p class="editorial__info-date text-description">
          {{ $moment(editorial.publish_date).format("MMM D, YYYY") }}
        </p>
      </div>
      <p class="editorial__description text-description">{{ editorial.description }}</p>
      <a
        class="editorial__link editorial__button"
        v-if="editorial.button_url"
        :href="editorial.button_url"
        target="_blank"
      >
        {{ `${editorial.button_text ? editorial.button_text : ""}` }}
      </a>
    </template>
  </section>
</template>

<script>
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Loader,
    BaseButton,
  },
  data() {
    return {
      loading: true,
      pageTitle: "GH - Publication",
    };
  },
  head: {
    title: function () {
      return {
        inner: this.pageTitle,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publicEditorials", {
      editorial: "getEditorial",
    }),
  },
  async created() {
    this.loading = true;
    const { editorialSlug } = this.$route.params;
    await this.apiGetEditorial(editorialSlug);
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
      {
        title: "Publications",
        to: "/library/publications",
      },
      {
        title: `${this.editorial.title}`,
        to: `/library/publications/${editorialSlug}`,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.pageTitle = `GH - ${this.editorial.title}`;
    this.$emit("updateHead");
    this.loading = false;
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicEditorials", ["apiGetEditorial"]),
  },
};
</script>

<style lang="scss" scoped>
.editorial {
  padding: 20rem 16.4rem 9rem;
  min-width: 100vw;
  flex-grow: 1;
  @media (max-width: $xxs) {
    padding: 30rem 2.5rem 7rem;
  }
  &__title {
    max-width: 99.8rem;
    @media (max-width: $xxs) {
      font-size: 3.7rem;
      line-height: 1.2;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    @media (max-width: $xxs) {
      margin: 1rem 0;
    }
    &-author {
      margin-right: 15px;
      font-weight: 600;
      line-height: 2.44;
    }
    &-date {
      font-weight: 600;
      line-height: 2.44;
    }
  }
  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.5;
    padding: 0 25px;
    border-radius: 34px;
    border: 1px solid $white;
    height: 4.5rem;
    width: max-content;
    margin-bottom: 4rem;
    min-width: 12.6rem;
    text-decoration: none;
    @media (max-width: $xxs) {
      margin-bottom: 2.6rem;
      min-width: 11rem;
    }
  }
  &__description {
    font-size: 2.1rem;
    max-width: 99.8rem;
    margin-bottom: 3.5rem;
    @media (max-width: $xxs) {
      margin-bottom: 2.5rem;
      font-size: 1.8rem;
    }
  }
  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 34px;
    background-color: $white;
    color: $black;
    font-family: $fontBodoni;
    height: 7rem;
    padding: 0 24px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    @media (max-width: $xxs) {
      height: 5.5rem;
      padding: 0 12px;
    }
    @media (max-width: $xxxs) {
      font-size: 1.6rem;
    }
    &:hover {
      background-color: $light2Grey;
    }
  }
  &__button {
    width: max-content;
    min-width: 28rem;
  }
}
</style>
