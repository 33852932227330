<template>
  <transition name="loader__fade">
    <div class="loader" :class="{ 'loader--finish': percent == 100 }">
      <img
        class="logo"
        v-webp="require('@/assets/images/TGHLogoWhite.png')"
        src="@/assets/images/TGHLogoWhite.png"
        alt="logo"
      />
      <div class="loader__progress__container">
        <div class="loader__progress" :style="{ width: `${percent}%` }">
          <div class="loader__progress__point"></div>
        </div>
      </div>
      <h4 class="text-base">Loading {{ percent }}%</h4>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      percent: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
  watch: {
    loading: {
      handler: function (newVal) {
        if (newVal) {
          this.percent = 0;
          this.timerId = setInterval(() => {
            if (this.percent <= 96) this.percent += 1;
            else clearInterval(this.timerId);
          }, 55);
        } else {
          clearInterval(this.timerId);
          this.percent = 100;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  background-color: $lightBlack;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 28px 0 116px;
  z-index: 1000;
  &__fade {
    &-leave-active {
      animation: loadFinish 1s ease-in 0.8s forwards;
    }
  }
  &--finish {
    animation: loadFinish 1s ease-in 0.8s forwards;
  }
  h4 {
    font-size: 2.5rem;
    line-height: 2;
    letter-spacing: -0.42px;
  }
  &__progress {
    height: 4px;
    background-color: $red;
    position: relative;
    &__container {
      display: flex;
      align-items: center;
      width: 100%;
      flex: 1;
    }
    &__point {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background-color: $red;
    }
  }
}
.logo {
  width: 18.75rem;
  height: 15rem;
}
@keyframes loadFinish {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -100%;
    opacity: 0.1;
  }
}
</style>
